import { Input, Output, EventEmitter, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Product } from '../../Model/Catalog/Product';
import { HazmatClass } from '../../Model/Catalog/HazmatClass';
import { WarrantyClass } from '../../Model/Catalog/WarrantyClass';
import { UserManagementService } from '../../Services/userManagment.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ValidationService } from '../../Services/Validation/validation.service';
import { ViewService } from 'app/Services/view.service';
import { ProductDataSource } from 'app/Model/dataProcess/productDataSource';
import { DataSourcesService } from 'app/Services/DataProcess/datasources.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../Services/login.service';
import { ProductService } from 'app/Services/CatalogManagement/product.service';
import { fieldNames } from 'app/Model/FieldNames';

@Component({
  selector: 'productLegal',
  templateUrl: 'productLegal.component.html',
  styleUrls: ['./productLegal.component.css']
})
export class ProductLegalComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() productTranslation: Product = null;
  @Input() showLinkButtons: Boolean = false;
  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [''];
  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = 'noDrag';
  @Input() isPrint: Boolean = false;
  @Input() showHTMLSwitch: Boolean = true;
  @Input() warrantyClasses: WarrantyClass[] = [];
  @Input() hazmatClasses: HazmatClass[] = [];

  @Output() onValueChanged = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();
  @Output() excelElementDropped = new EventEmitter<any>();

  weeeDeviceTypes = ViewService.legalWeeeDeviceTypes;
  manufacturerNames: string[] = [];

  productDataSource: ProductDataSource = null;
  productDataSources: ProductDataSource[] = [];

  private _showHTMLEditor: Boolean = true;

  get showHTMLEditor() {
    return this._showHTMLEditor;
  }

  set showHTMLEditor(value) {
    this._showHTMLEditor = value;
  }

  get hasTranslation(): Boolean {
    if (this.productTranslation == null) {
      return false;
    }
    return true;
  }

  constructor(
    public validationService: ValidationService,
    public userManagement: UserManagementService,
    public catalogService: CatalogService,
    public dataSourceService: DataSourcesService,
    public translate: TranslateService,
    public loginService: LoginService,
    public productService: ProductService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSourceService.getAll(this.userManagement.loginService.currentCustomer.id).then((result) => {
      this.manufacturerNames = result.map((x) => x.name);
      this.productDataSources = result;
      this.productDataSource = result.find(
        (x) =>
          x.name == this.model.legalCharacteristic.manufacturer ||
          x.alternativeNames.includes(this.model.legalCharacteristic.manufacturer)
      );
    });
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  async onManufacturerChange(event) {
    if (this.templateView) {
      return this.update(event, fieldNames.productSafetyManufacturer);
    }

    if (!event.event) event.event = new Event('dummy');

    this.productDataSource = this.productDataSources.find(
      (x) =>
        x.name == this.model.legalCharacteristic.manufacturer ||
        x.alternativeNames.includes(this.model.legalCharacteristic.manufacturer)
    );

    this.update(event, fieldNames.productSafetyManufacturer);
    if (this.model.legalCharacteristic.manufacturer?.trim()) {
      this.model.legalCharacteristic.contact = await this.productService.getSupportContact(
        this.catalogService.catalog.id,
        this.model.id,
        this.loginService.currentCustomer.id,
        this.model.legalCharacteristic.manufacturer
      );
      this.update(event, fieldNames.productSafetyContact);
    }

    this.cdr.detectChanges();
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  showHTMLEditorChanged() {
    this.catalogService
      .getProduct(this.model.id, this.model.customerId, this.catalogService.catalog.id)
      .subscribe(() => (this.model = this.catalogService.product));
  }

  //ExcelTemplate

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) {
        return true;
      }
    }
    return false;
  }

  public showClearButton(): boolean {
    return !this.templateView;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }

    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }

    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }

  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}
