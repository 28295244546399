import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TemplateService } from 'app/Services/template.service';
import { ValidationService } from 'app/Services/Validation/validation.service';
import DataSource from 'devextreme/data/data_source';
import dxDropDownBox, { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { ItemClickEvent } from 'devextreme/ui/list';
import { CustomItemCreatingEvent } from 'devextreme/ui/select_box';
import { InitializedEvent } from 'devextreme/ui/validator';

type ValidateFn = (params: any) => boolean;
type FormatDisplayValueFn = (value: string) => string;
type DropdownItem = { value: string; displayValue: string };

@Component({
  selector: 'np-dropdown-control',
  templateUrl: './dropdown-control.component.html',
  styleUrl: './dropdown-control.component.css'
})
export class DropdownControlComponent implements OnInit, OnChanges {
  @ViewChild('dropdown', { static: false }) dropdownComponent!: dxDropDownBox;

  @Input() model: any = null;
  @Input() modelProp: string;
  @Input() value = '';

  @Input() fieldName: string;
  @Input() systemKey = '-1';
  @Input() elementKey = '-1';
  @Input() readOnly = false;
  @Input() templateView = false;
  @Input() validationGroup: string;
  @Input() validationCallback: ValidateFn = null;
  @Input() items: DropdownItem[] | string[] = [];
  @Input() formatDisplayValue: FormatDisplayValueFn = (value: string) => {
    return (
      this.translate.instant(this.translationLabelPrefix !== null ? `${this.translationLabelPrefix}${value}` : value) +
      (this.showRealValue ? ` (${value})` : '')
    );
  };
  @Input() selectionMode: 'single' | 'multiple' = 'single';
  @Input() translationLabelPrefix: string = null;
  @Input() showRealValue = false;

  @Output() onValueChanged = new EventEmitter<ValueChangedEvent>();
  @Output() onCustomItemCreating = new EventEmitter<CustomItemCreatingEvent>();
  @Output() onElementSelected = new EventEmitter<ItemClickEvent<DropdownItem>>();

  customItems: string[] = [];
  opened = false;
  dataSource: DataSource<DropdownItem>;

  constructor(public translate: TranslateService, public validationService: ValidationService, private templateService: TemplateService) {}

  ngOnInit() {
    this.updateDataSource();

    this.templateService.clearPimFieldEmitter.subscribe((pimField) => {
      if (pimField.field === this.fieldName) {
        this.value = this.model[this.modelProp] = '';
      }
    })

    if (this.validationCallback === null) {
      this.validationCallback = this.validationService.validateField(this.fieldName, this.systemKey, this.elementKey);
    }
    if (this.model && this.modelProp) this.value = this.model[this.modelProp];
  }

  ngOnChanges(changes: SimpleChanges) {
    // The object must be replaced in order to be noticed in ngOnChange.
    if (changes.items) {
      this.updateDataSource();
    }
  }

  valueChanged(event: ValueChangedEvent) {
    if (this.model && this.modelProp) this.model[this.modelProp] = event.value;
    this.value = event.value;
    this.onValueChanged.emit(event);
  }

  customItemCreating(event: CustomItemCreatingEvent) {
    this.customItems = [...new Set([...this.customItems, event.text])];
    this.updateDataSource();
    this.onCustomItemCreating.emit(event);
  }

  itemClick(event: ItemClickEvent<DropdownItem>) {
    if (this.model && this.modelProp) this.model[this.modelProp] = event.itemData.value;
    this.value = event.itemData.value;
    this.opened = false;
    this.onElementSelected.emit(event);
    this.onValueChanged.emit({
      component: this.dropdownComponent,
      element: event.element,
      value: event.itemData.value,
      event: event.event
    });
  }

  private updateDataSource() {
    if (this.items.every((x) => typeof x === 'string')) {
      const items = Array.from(new Set([...this.items, ...this.customItems]));
      this.dataSource = new DataSource(
        items.map((value: string) => ({ value, displayValue: this.formatDisplayValue(value) } as const))
      );
    } else {
      this.dataSource = new DataSource(this.items as DropdownItem[]);
    }
  }
}
