import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from 'app/Services/Validation/validation.service';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { ItemClickEvent } from 'devextreme/ui/list';
import { CustomItemCreatingEvent } from 'devextreme/ui/select_box';
import { getDisplayPrice } from '../utils/prices';

type ValidateFn = (params: any) => boolean;
type FormatDisplayValueFn = (value: string) => string;
type DropdownItem = { value: string; displayValue: string };

@Component({
  selector: 'np-number-select-control',
  templateUrl: './number-select-control.component.html',
  styleUrl: './number-select-control.component.css'
})
export class NumberSelectControlComponent implements OnInit, OnChanges {
  @Input() model: any = null;
  @Input() modelProp: string;
  // @Input() value = '';

  @Input() fieldName: string;
  @Input() systemKey = '-1';
  @Input() elementKey = '-1';
  @Input() readOnly = false;
  @Input() templateView = false;
  @Input() validationGroup: string;
  @Input() numberFormatStyle: 'currency' | 'percent' | 'standard' = 'standard';
  @Input() currency: string;
  @Input() validationCallback: ValidateFn = null;
  @Input() items: DropdownItem[] | string[] = [];
  @Input() formatDisplayValue: FormatDisplayValueFn = (value: string) => {
    const number = Number(value);
    let result: string;
    if (Number.isNaN(number)) result = value;
    else if (this.numberFormatStyle === 'currency' && this.currency) result = getDisplayPrice(number, this.currency, this.translate.currentLang);
    else if (this.numberFormatStyle === 'percent') result = `${number.toLocaleString(this.translate.currentLang, { style: 'percent' })}`;
    else result= `${number.toLocaleString(this.translate.currentLang)}`;
    return (this.showRealValue ? `${value} (${result})` : result);
  };
  @Input() selectionMode: 'single' | 'multiple' = 'single';
  @Input() translationLabelPrefix: string = null;
  @Input() showRealValue = false;

  @Output() onValueChanged = new EventEmitter<ValueChangedEvent>();
  @Output() onCustomItemCreating = new EventEmitter<CustomItemCreatingEvent>();
  @Output() onElementSelected = new EventEmitter<ItemClickEvent<DropdownItem>>();

  customItems: string[] = [];
  opened = false;
  dataSource: DataSource<DropdownItem>;

  get value() {
    return this.model[this.modelProp];
  }

  set value(value: string | number) {
    this.model[this.modelProp] = Number(value);
  }

  constructor(public translate: TranslateService, public validationService: ValidationService) {}

  ngOnInit() {
    this.updateDataSource();
    if (this.validationCallback === null) {
      this.validationCallback = this.validationService.validateField(this.fieldName, this.systemKey, this.elementKey);
    }
    if (this.model && this.modelProp) this.value = this.model[this.modelProp];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.updateDataSource();
    }
  }

  valueChanged(event: ValueChangedEvent) {
    if (this.model && this.modelProp) this.model[this.modelProp] = event.value;
    this.value = event.value;
    this.onValueChanged.emit(event);
  }

  customItemCreating(event: CustomItemCreatingEvent) {
    this.customItems = [...new Set([...this.customItems, event.text])];
    this.updateDataSource();
    this.onCustomItemCreating.emit(event);
  }

  itemClick(event: ItemClickEvent<DropdownItem>) {
    if (this.model && this.modelProp) this.model[this.modelProp] = event.itemData.value;
    this.value = event.itemData.value;
    this.opened = false;
    this.onElementSelected.emit(event);
    this.onValueChanged.emit();
  }

  private updateDataSource() {
    if (this.items.every((x) => typeof x === 'string' || typeof x === 'number')) {
      const items = Array.from(new Set([...this.items, ...this.customItems]));
      this.dataSource = new DataSource(
        items.map((value: string) => ({ value, displayValue: this.formatDisplayValue(value) } as const))
      );
    } else {
      this.dataSource = new DataSource(this.items as DropdownItem[]);
    }
    this.dataSource.reload();
  }
}
