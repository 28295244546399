<div class="templateExcelExportColumn">
  <dx-scroll-view
    showScrollbar="always"
    >
  <div class="dx-fieldset d-flex flex-column">
    <div class="dx-field d-flex flex-column">
      <div class="dx-field-label2">{{ 'Umrechnung' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ templateItem.calculation?.length > 0 ? 'placeLabelSmall' : '' }}">
          CALCULATION
        </label>
        <dx-select-box [dataSource]="calculation"
                       [displayExpr]="displayExpr"
                       valueExpr="valueExpr"
                       placeholder=""
                       [(value)]="templateItem.calculation"
                       (onValueChanged)="update()"></dx-select-box>
      </div>
    </div>
    <div *ngIf="templateItem.calculation == 'FACTOR'" class="container np-row" [ngStyle]="{ marginBottom: '30px' }">
      <div class="row mb-2">
        <div class="col-12 p-0">
          <div>{{ 'Umrechnungsfaktor' | translate }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 pl-0 pr-1 dx-field-value" [ngClass]="showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder'">
          <label class="placeLabel {{ templateItem.factorOperator?.length > 0 && templateItem.factorOperator !== 'NONE' ? 'placeLabelSmall' : '' }}">{{ 'Operator' | translate | uppercase }}</label>
          <dx-select-box [dataSource]="operators"
                         displayExpr="displayExprExport"
                         valueExpr="valueExpr"
                         placeholder=""
                         [(value)]="templateItem.factorOperator"
                         (onValueChanged)="update()"></dx-select-box>
        </div>
        <div class="col-6 pl-1 pr-0 dx-field-value" [ngClass]="showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder'">
          <label class="placeLabel {{ templateItem.factor > 0 ? 'placeLabelSmall' : '' }}">{{ 'Faktor' | translate | uppercase }}</label>
          <dx-number-box [(value)]="templateItem.factor"
                         [min]="1"
                         placeholder=""
                         (onValueChanged)="update()"></dx-number-box>
        </div>
      </div>
    </div>
    <div class="dx-field d-flex flex-column">
      <div class="dx-field-label2">{{ 'Zahlenformat' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ templateItem.numberformat?.length > 0 ? 'placeLabelSmall' : '' }}">
          NUMBERFORMAT
        </label>
        <dx-select-box [dataSource]="numberformat"
                       displayExpr="displayExprExport"
                       valueExpr="valueExpr"
                       placeholder=""
                       [(value)]="templateItem.numberformat"
                       (onValueChanged)="update()"></dx-select-box>
      </div>
    </div>
    <div class="dx-field d-flex flex-column">
      <div class="dx-field-label2">{{ 'Trennung' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ templateItem.seperator?.length > 0 ? 'placeLabelSmall' : '' }}">SEPERATOR</label>
        <dx-select-box [dataSource]="seperator"
                       displayExpr="displayExprExport"
                       valueExpr="valueExpr"
                       placeholder=""
                       [(value)]="templateItem.seperator"
                       (onValueChanged)="update()"></dx-select-box>
      </div>
    </div>
    <div class="dx-field d-flex flex-column">
      <div class="dx-field-label2">{{ 'Nur beim ersten Import' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <dx-switch [(value)]="templateItem.isOnlyInitial"
                   (onValueChanged)="update()"></dx-switch>
      </div>
    </div>
    <div class="dx-field d-flex flex-column">
      <div class="dx-field-label2">{{ 'Mapping' | translate }}</div>
      <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ templateItem.mapping?.length > 0 ? 'placeLabelSmall' : '' }}">MAPPING</label>
        <dx-select-box [dataSource]="mappings"
                       displayExpr="listName"
                       valueExpr="id"
                       placeholder=""
                       [(value)]="templateItem.mapping"
                       (onValueChanged)="update()"></dx-select-box>
      </div>
    </div>

    <div class="container np-row" [ngStyle]="{ marginBottom: '30px' }">
      <div class="row mb-2">
        <div class="col-12 p-0">
          <div>{{ 'Textzusatz' | translate }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 pl-0 pr-1 dx-field-value" [ngClass]="showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder'">
          <label class="placeLabel {{ templateItem.prefix?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'Präfix' | translate | uppercase }}</label>
          <dx-text-box displayExpr="displayExprExport"
                       valueExpr="valueExpr"
                       labelMode="outside"
                       placeholder=""
                       [(value)]="templateItem.prefix"
                       (onValueChanged)="update()"></dx-text-box>
        </div>
        <div class="col-6 pl-1 pr-0 dx-field-value" [ngClass]="showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder'">
          <label class="placeLabel {{ templateItem.suffix?.length > 0 ? 'placeLabelSmall' : '' }}">{{ 'Suffix' | translate | uppercase }}</label>
          <dx-text-box displayExpr="displayExprExport"
                       valueExpr="valueExpr"
                       labelMode="outside"
                       placeholder=""
                       [(value)]="templateItem.suffix"
                       (onValueChanged)="update()"></dx-text-box>
        </div>
      </div>
    </div>

    <div class="container np-row">
      <div class="row mb-2">
        <div class="col-12 p-0">
          <div>{{ 'Automatische Trennung' | translate }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 pl-0 pr-1">
          <dx-number-box displayExpr="displayExprExport"
                         valueExpr="valueExpr"
                         label="{{ 'Max. Zeichenlänge' | translate }}"
                         labelMode="outside"
                         [(value)]="templateItem.maxCharacters"
                         [min]="0"
                         (onValueChanged)="update()"></dx-number-box>
        </div>
        <div class="col-6 pl-1 pr-0">
          <dx-number-box displayExpr="displayExprExport"
                         valueExpr="valueExpr"
                         label="{{ 'Abschnitt' | translate }}"
                         labelMode="outside"
                         [(value)]="templateItem.section"
                         [min]="1"
                         (onValueChanged)="update()"></dx-number-box>
        </div>
      </div>
    </div>
  </div>
  </dx-scroll-view>
</div>
