
export class ValidationResult {
  
  constructor(

    public validateNumbers0?: number,
    public validateNumbers1?: number,
    public validateNumbers2?: number,
    public validateNumbers3?: number,
    public validateNumbers4?: number,
    public validateNumbers: number[] = [0, 0, 0, 0, 0],
    public validGroup?: number,
    public validGroupName?: string,
    public totalGroupCount?: number,
    public productInfosValid?: boolean,
    public productMimesValid?: boolean,
    public productFeaturesValid?: boolean,
    public productOrderDetailsValid?: boolean,
    public productPricesValid?: boolean,
    public productLogisticsValid?: boolean,
    public productReferenceValid?: boolean,
    public productUdxsValid?: boolean,
    public productSeoValid?: boolean,
    public productLegalValid?: boolean,
    public productCustomFieldsValid?: boolean,
    public productSuppliersValid?: boolean,
    public productPackingUnitsValid?: boolean,
    public mediaMessages?: string[],
    public featureMessages?: string[],
    public referenceMessages?: string[],
    public priceMessages?: string[],
    public suppliersMessages?: string[],
    public missingForNextGroup?: string[],
    public validationGroupColors?: string[]
  ) {

  }
}
