<div class="np-form-control dx-fieldset">
  <dx-validation-group *ngIf="model" id="ESALES">
    <div class="dx-fieldset grid-view" *ngIf="loginService.hasAddonElectronicSales">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Artikel' | translate }}</div>
        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.dateCreated !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_DATE_CREATED' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_DATE_CREATED"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-date-box
                [min]="minDate"
                [max]="now"
                [(value)]="model.electronicSales.dateCreated"
                [showClearButton]="showClearButton()"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_DATE_CREATED')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_DATE_CREATED')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.newInShopTime !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_NEWINSHOP_TIME' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_NEWINSHOP_TIME"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-date-box
                [min]="minDate"
                [max]="now"
                [(value)]="model.electronicSales.newInShopTime"
                [showClearButton]="showClearButton()"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_NEWINSHOP_TIME')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_NEWINSHOP_TIME')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-date-box>
            </div>
          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.materialGroup !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_MATERIAL_GROUP' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_MATERIAL_GROUP"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-text-box
                [(value)]="model.electronicSales.materialGroup"
                [showClearButton]="showClearButton()"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_MATERIAL_GROUP')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_MATERIAL_GROUP')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </np-inputControl>
        </div>

        <div *ngFor="let kv of validationService.fieldNames.electronicSalesBoolMapping | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-switch [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-switch>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Marketing' | translate }}</div>
        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.descriptionLong !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_DESCRIPTION_LONG' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_DESCRIPTION_LONG"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-text-area
                [(value)]="model.electronicSales.descriptionLong"
                [showClearButton]="showClearButton()"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_DESCRIPTION_LONG')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_DESCRIPTION_LONG')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-area>
            </div>
          </np-inputControl>
        </div>

        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.noteCustomer !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_NOTE_CUSTOMER' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_NOTE_CUSTOMER"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-text-box
                [(value)]="model.electronicSales.noteCustomer"
                [showClearButton]="showClearButton()"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_NOTE_CUSTOMER')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_NOTE_CUSTOMER')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </np-inputControl>
        </div>
        <div *ngFor="let kv of marketingMap | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
        <div class="np-field">
          <np-inputControl [(model)]="model"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="model?.electronicSales?.seoUrlFilename !== null"
                           [(selectedElements)]="selectedElements"
                           (elementSelected)="elementSelectFunction($event)"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'ELECTRONIC_SALES_SEOURL_FILENAME' | translate }}"
                           [suppressLinkButton]="true"
                           [suppressPlaceLabel]="true"
                           [suppressDragHandle]="true"
                           field="ELECTRONIC_SALES_SEOURL_FILENAME"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)"
          >
            <div slot="content" class="contentSlot">
              <dx-text-box
                [(value)]="model.electronicSales.seoUrlFilename"
                (onValueChanged)="update($event, 'ELECTRONIC_SALES_SEOURL_FILENAME')"
              >
                <dx-validator *ngIf="!templateView"
                              validationGroup="ESALES"
                              (onInitialized)="validationService.startAutomaticValidation($event)">
                  <dxi-validation-rule
                    type="async"
                    [validationCallback]="validationService.validateField('ELECTRONIC_SALES_DATE_CREATED')"
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </np-inputControl>
        </div>
        <div *ngFor="let kv of productDetail | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Klassifizierungen' | translate }}</div>
        <div *ngFor="let kv of classificationMap | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Logistik' | translate }}</div>

        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.electronicSales?.stockKeeping !== null"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'ELECTRONIC_SALES_STOCKKEEPING' | translate }}"
                         [suppressLinkButton]="true"
                         [suppressPlaceLabel]="true"
                         [suppressDragHandle]="true"
                         field="ELECTRONIC_SALES_STOCKKEEPING"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)"
        >
          <div slot="content" class="contentSlot">
            <dx-select-box
              valueExpr="key"
              displayExpr="text"
              [items]="stockKeepingItems"
              [(value)]="model.electronicSales.stockKeeping"
              (onValueChanged)="update($event, 'ELECTRONIC_SALES_STOCKKEEPING')"
            >
              <dx-validator *ngIf="!templateView"
                            validationGroup="ESALES"
                            (onInitialized)="validationService.startAutomaticValidation($event)">
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="validationService.validateField('ELECTRONIC_SALES_STOCKKEEPING')"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </np-inputControl>

        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.electronicSales?.stockKeepingModus !== null"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'ELECTRONIC_SALES_STOCKKEEPING_MODUS' | translate }}"
                         [suppressLinkButton]="true"
                         [suppressPlaceLabel]="true"
                         [suppressDragHandle]="true"
                         field="ELECTRONIC_SALES_STOCKKEEPING_MODUS"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)"
        >
          <div slot="content" class="contentSlot">
            <dx-select-box
              valueExpr="key"
              displayExpr="text"
              [items]="stockKeepingModusItems"
              [(value)]="model.electronicSales.stockKeepingModus"
              (onValueChanged)="update($event, 'ELECTRONIC_SALES_STOCKKEEPING_MODUS')"
            >
              <dx-validator *ngIf="!templateView"
                            validationGroup="ESALES"
                            (onInitialized)="validationService.startAutomaticValidation($event)">
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="validationService.validateField('ELECTRONIC_SALES_STOCKKEEPING_MODUS')"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </np-inputControl>

        <div *ngFor="let kv of logisticsMap | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Kosten' | translate }}</div>
        <div *ngFor="let kv of chargesMap | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.electronicSales[kv.value]" (onValueChanged)="update($event, kv.key)">
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">{{ 'Bestellung' | translate }}</div>

        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.electronicSales?.orderDecimalPlaces !== null"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'ELECTRONIC_SALES_ORDER_DECIMAL_PLACES' | translate }}"
                         [suppressLinkButton]="true"
                         [suppressPlaceLabel]="true"
                         [suppressDragHandle]="true"
                         field="ELECTRONIC_SALES_ORDER_DECIMAL_PLACES"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)"
        >
          <div slot="content" class="contentSlot">
            <dx-number-box
              [(value)]="model.electronicSales.orderDecimalPlaces"
              [min]="0.0"
              format="#0.##"
              (onValueChanged)="update($event, 'ELECTRONIC_SALES_ORDER_DECIMAL_PLACES')"
            >
              <dx-validator *ngIf="!templateView"
                            validationGroup="ESALES"
                            (onInitialized)="validationService.startAutomaticValidation($event)">
                <dxi-validation-rule
                  type="async"
                  [validationCallback]="validationService.validateField('ELECTRONIC_SALES_ORDER_DECIMAL_PLACES')"
                ></dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </div>
        </np-inputControl>

        <div *ngFor="let kv of ordersMap | keyvalue">
          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.electronicSales != null && model?.electronicSales[kv.value] != null"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ kv.key | translate }}"
                             [suppressLinkButton]="true"
                             [suppressPlaceLabel]="true"
                             [suppressDragHandle]="true"
                             field="{{ kv.key }}"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)"
            >
              <div slot="content" class="contentSlot">
                <dx-number-box
                  [(value)]="model.electronicSales[kv.value]"
                  [min]="0.0"
                  format="#0.##"
                  (onValueChanged)="update($event, kv.key)"
                >
                  <dx-validator *ngIf="!templateView"
                                validationGroup="ESALES"
                                (onInitialized)="validationService.startAutomaticValidation($event)">
                    <dxi-validation-rule
                      type="async"
                      [validationCallback]="validationService.validateField(kv.key)"
                    ></dxi-validation-rule>
                  </dx-validator>
                </dx-number-box>
              </div>
            </np-inputControl>
          </div>
        </div>
      </div>
    </div>
  </dx-validation-group>
</div>
