import { Address } from "./Address";
import { Mime } from "./Mime";

export class Supplier {

  constructor(
    public supplierName?: string,
    public supplierIdType?: string,
    public supplierId?: string,
    public address?: Address,
    public mime?: Mime,
    public manufacturer?: string,
    public contact?: string
  ) {
    this.address = new Address();
  }
}
