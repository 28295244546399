<div class="np-form-control dx-fieldset mainInfos" *ngIf="model">
  <!--SUPPLIER_NAME-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Lieferantenname' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.supplierName?.length > 0 ? 'placeLabelSmall' : '' }}">
        SUPPLIER_NAME
      </label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierName')"
        [(value)]="model?.supplier.supplierName"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--SUPPLIER_ID-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Lieferantenkennung' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.supplierId?.length > 0 ? 'placeLabelSmall' : '' }}">SUPPLIER_ID</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierId')"
        [(value)]="model?.supplier.supplierId"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--SUPPLIER_ID_TYPE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'ID-Typ' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.supplierIdType?.length > 0 ? 'placeLabelSmall' : '' }}">
        SUPPLIER_ID_TYPE
      </label>
      <dx-autocomplete
        [minSearchLength]="0"
        [dataSource]="idType"
        [showClearButton]="true"
        [showDropDownButton]="true"
        [(value)]="model?.supplier.supplierIdType"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
        [readOnly]="readOnlyUser('supplierType')"
      ></dx-autocomplete>
    </div>
  </div>

  <!--NAME1-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 1' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address?.name1?.length > 0 ? 'placeLabelSmall' : '' }}">NAME1</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressName1')"
        [(value)]="model?.supplier.address.name1"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--NAME2-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 2' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.name2?.length > 0 ? 'placeLabelSmall' : '' }}">NAME2</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressName2')"
        [(value)]="model?.supplier.address.name2"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--NAME3-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Adresszeile 3' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.name3?.length > 0 ? 'placeLabelSmall' : '' }}">NAME3</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressName3')"
        [(value)]="model?.supplier.address.name3"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--CONTACT-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Ansprechpartner' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.contact?.length > 0 ? 'placeLabelSmall' : '' }}">
        CONTACT
      </label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddresssContact')"
        [(value)]="model?.supplier.address.contact"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--STREET-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Straße' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.street?.length > 0 ? 'placeLabelSmall' : '' }}">STREET</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressStreet')"
        [(value)]="model.supplier.address.street"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--ZIP-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'PLZ' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.zip?.length > 0 ? 'placeLabelSmall' : '' }}">ZIP</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressZip')"
        [(value)]="model.supplier.address.zip"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--CITY-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Ort' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.city?.length > 0 ? 'placeLabelSmall' : '' }}">CITY</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressCity')"
        [(value)]="model.supplier.address.city"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--STATE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Bundesland' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.state?.length > 0 ? 'placeLabelSmall' : '' }}">STATE</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressState')"
        [(value)]="model.supplier.address.state"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--COUNTRY-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Land' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.country?.length > 0 ? 'placeLabelSmall' : '' }}">
        COUNTRY
      </label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressCountry')"
        [(value)]="model.supplier.address.country"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--PHONE-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Telefon' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.phone?.length > 0 ? 'placeLabelSmall' : '' }}">PHONE</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressPhone')"
        [(value)]="model.supplier.address.phone"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--FAX-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Telefax' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.fax?.length > 0 ? 'placeLabelSmall' : '' }}">FAX</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressFax')"
        [(value)]="model.supplier.address.fax"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--EMAIL-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'E-Mail' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.email?.length > 0 ? 'placeLabelSmall' : '' }}">EMAIL</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressEmail')"
        [(value)]="model.supplier.address.email"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--URL-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Homepage' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.url?.length > 0 ? 'placeLabelSmall' : '' }}">URL</label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressUrl')"
        [(value)]="model.supplier.address.url"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <!--ADDRESS_REMARKS-->
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Bemerkung' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplier.address.addressRemarks?.length > 0 ? 'placeLabelSmall' : '' }}">
        ADDRESS_REMARKS
      </label>
      <dx-text-box
        [readOnly]="readOnlyUser('supplierAddressRemarks')"
        [(value)]="model.supplier.address.addressRemarks"
        [showClearButton]="true"
        valueChangeEvent="focusout"
        (onValueChanged)="catalogChanged($event)"
      ></dx-text-box>
    </div>
  </div>

  <div class="dx-card">
    <div class="dx-fieldset d-flex flex-column gap-4">
      <div class="dx-fieldset-header">{{ 'Produktsicherheitsverordnung (GPSR)' | translate }}</div>
      <div class="dx-field">
        <div class="dx-field-label dx-field-label200">{{ 'Hersteller' | translate }}</div>
        <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ model.supplier.manufacturer?.length > 0 ? 'placeLabelSmall' : '' }}">
            MANUFACTURER
          </label>
          <div class="d-flex gap-4 align-items-center">
            <np-dropdown-control
              class="flex-grow-1"
              [readOnly]="readOnlyUser('catalogSupportManufacturer')"
              [templateView]="false"
              [items]="manufacturerNames"
              [(model)]="model.supplier"
              modelProp="manufacturer"
              (onValueChanged)="onManufacturerChange($event)"
            ></np-dropdown-control>
            <img
              *ngIf="productDataSource?.logoFile"
              src="{{ productDataSource.logoFile }}"
              [ngStyle]="{ height: '49px', maxWidth: '120px' }"
            />
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label dx-field-label200">
          <div
            [ngClass]="{
              'd-flex': true,
              'flex-column': true,
              'dx-field-label-np': true,
              'dx-field-label': true,
              'align-self-stretch': false
            }"
          >
            <div>{{ 'Ansprechpartner' | translate }}</div>
            <div class="d-flex flex-row py-2">
              <div class="dx-field-label switch-left">{{ 'Text' | translate }}</div>
              <dx-switch
                [(value)]="showHTMLEditor"
                [readOnly]="readOnlyUser('catalogSupportContact')"
              ></dx-switch>
              <div class="dx-field-label switch-right">{{ 'html' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <div *ngIf="showHTMLEditor && !isPrint" class="w-100">
            <dx-html-editor
              class="w-100"
              [multiline]="false"
              [height]="250"
              [(value)]="model.supplier.contact"
              placeholder="CONTACT"
              [readOnly]="readOnlyUser('catalogSupportContact')"
              (onFocusOut)="catalogChanged($event)"
              allowSoftLineBreak="true"
            >
              <dxo-toolbar>
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
              </dxo-toolbar>
            </dx-html-editor>
          </div>

          <div *ngIf="!showHTMLEditor || isPrint">
            <label class="placeLabel {{ model.supplier.contact?.length > 0 ? 'placeLabelSmall' : '' }}">CONTACT</label>
            <dx-text-area
              [(value)]="model.supplier.contact"
              [height]="250"
              [readOnly]="readOnlyUser('catalogSupportContact')"
              (onValueChanged)="catalogChanged($event)"
            ></dx-text-area>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
