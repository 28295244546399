import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Catalog } from '../../Model/Catalog/Catalog';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '../../Services/userManagment.service';
import { CatalogService } from 'app/Services/CatalogManagement/catalog.service';
import { LoginService } from 'app/Services/login.service';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { DataSourcesService } from 'app/Services/DataProcess/datasources.service';
import { ProductDataSource } from 'app/Model/dataProcess/productDataSource';
import { ProductService } from 'app/Services/CatalogManagement/product.service';

@Component({
  selector: 'catalogSupplier',
  templateUrl: 'catalogSupplier.component.html',
  styleUrls: []
})
export class CatalogSupplierComponent implements OnInit {
  manufacturerNames: string[] = [];
  showHTMLEditor = true;

  productDataSources: ProductDataSource[] = [];
  productDataSource: ProductDataSource = null;

  get model(): Catalog {
    return this.catalogService.catalog;
  }

  constructor(
    public catalogService: CatalogService,
    public loginService: LoginService,
    public translate: TranslateService,
    public userManagement: UserManagementService,
    public dataSourceService: DataSourcesService,
    public productService: ProductService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.dataSourceService.getAll(this.userManagement.loginService.currentCustomer.id).then((result) => {
      this.manufacturerNames = result.map((x) => x.name);
      this.productDataSources = result;
      this.productDataSource = result.find(
        (x) => x.name == this.model.supplier.manufacturer || x.alternativeNames.includes(this.model.supplier.manufacturer)
      );
    });
  }

  catalogChanged(e: ValueChangedEvent) {
    this.catalogService.catalogChanged(e);
  }

  async onManufacturerChange(event) {
    if (!event.event) event.event = new Event('dummy');

    this.productDataSource = this.productDataSources.find(
      (x) => x.name == this.model.supplier.manufacturer || x.alternativeNames.includes(this.model.supplier.manufacturer)
    );

    this.catalogChanged(event);
    if (this.model.supplier.manufacturer?.trim()) {
      this.model.supplier.contact = await this.productService.getSupportContact(
        this.catalogService.catalog.id,
        null,
        this.loginService.currentCustomer.id,
        this.model.supplier.manufacturer
      );
      this.catalogChanged(event);
    }

    this.cdr.detectChanges();
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}
