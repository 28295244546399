import { Component } from "@angular/core";
import { StartAction } from "../../../../Model/Dto/StartAction";
import { TranslateService } from "@ngx-translate/core";
import { UserActionBaseComponent } from "../userAction-base.component";
import { ModelService } from "app/Services/model.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: 'useraction-createUniqueGroupIds',
  templateUrl: './useraction-createUniqueGroupIds.component.html',
})
export class UseractioncreateUniqueGroupIdsComponent extends UserActionBaseComponent {
  useObjectIds: boolean = false;
  
  constructor(public modelService: ModelService, public translate: TranslateService) {
    super(modelService);
  }

  async startMacro() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = "CreateUniqueGroupId";
    startAction.actionType = "CreateUniqueGroupId";
    startAction.actionParameter = JSON.stringify({
      useObjectIds: this.useObjectIds
    });

    var next = await lastValueFrom(this.modelService.jobService.startUserAction(startAction));
    this.userActionStarted.emit(next);
  }

}
