export class OsgSegmentMapping {
  constructor(
    public lastSend?: Date,
    public productRange?: string,
    public segmentId?: string,
    public lastSendTest?: Date,
    public productRangeTest?: string,
    public segmentIdTest?: string,
    public keepRootCategory?: boolean,
    public includeHazmatClassesInDescriptionTest?: boolean,
    public includeHazmatClassesInDescription?: boolean,
    public includeLegal?: boolean,
    public categoriesAreSegments?: boolean,
    public priceType?: string,
    public priceTypeTest?: string
  ) {}
}
