<div class="dx-fieldset">
  <!--GROUP_STRING-->
  <div class="np-field">
    <np-inputControl
      [(model)]="model"
      [showLinkButtons]="false"
      [showPlaceLabel]="model?.groupString?.length > 0"
      [(selectedElements)]="selectedElements"
      (elementSelected)="elementSelectFunction($event)"
      (onValueChanged)="update($event)"
      labelText="{{ 'Kategorie' | translate }}"
      field="GROUP_STRING"
      [showDragHandle]="showDragHandle"
      [dragFilter]="dragFilter"
      (excelElementDropped)="excelElementDrop($event)"
    >
      <div slot="content" class="contentSlot">
        <dx-text-box
          [(value)]="model.groupString"
          [readOnly]="false"
          [showClearButton]="false"
          (onValueChanged)="update($event)"
          class="hint"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="{{ 'Bitte geben Sie eine Kategorie an' | translate }}!"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </np-inputControl>
  </div>

  <!--MASTER-->
  <div class="np-field">
    <np-inputControl
      [(model)]="model"
      [showLinkButtons]="false"
      [showPlaceLabel]="model?.master?.length > 0"
      [(selectedElements)]="selectedElements"
      (elementSelected)="elementSelectFunction($event)"
      (onValueChanged)="update($event)"
      labelText="{{ 'Variantenzuordnung' | translate }}"
      field="MASTER"
      [showDragHandle]="showDragHandle"
      [dragFilter]="dragFilter"
      (excelElementDropped)="excelElementDrop($event)"
    >
      <div slot="content" class="contentSlot">
        <dx-text-box
          [(value)]="model.master"
          [readOnly]="false"
          [showClearButton]="false"
          (onValueChanged)="update($event)"
          class="hint"
        ></dx-text-box>
      </div>
    </np-inputControl>
  </div>
</div>
